import { clientsTableChangeCurrentClientMutation as ClientsTableChangeCurrentClientMutationType } from "./__generated__/clientsTableChangeCurrentClientMutation.graphql"
import { clientsTableFragment$key } from "./__generated__/clientsTableFragment.graphql"
import styles from "./clients-table.module.scss"
import { HoverMenu } from "./hover-menu"
import {
  Avatar,
  Badge,
  Button,
  Group,
  HoverCard,
  Text,
  rem,
} from "@mantine/core"
import { updateCurrentMetabaseTokenCache } from "@shared/relay/utils/cache"
import { useUserStore } from "@shared/store"
import { Table } from "@shared/ui/table"
import { IconAlertTriangle } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { pathConstants } from "frontend/routes/path-constants"
import { graphql, useFragment, useMutation } from "react-relay"
import { useNavigate } from "react-router-dom"

const ClientsTableFragment = graphql`
  fragment clientsTableFragment on Query
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    getClientSummaries(first: $count, after: $cursor)
      @connection(key: "ClientsTableFragment_getClientSummaries") {
      edges {
        node {
          id
          name
          issues
          status
          logo
          cashBalance
          revenue
          closeMonth
        }
      }
    }
  }
`

const ClientsTableChangeCurrentClientMutation = graphql`
  mutation clientsTableChangeCurrentClientMutation(
    $input: ChangeCurrentClientMutationInput!
  ) {
    changeCurrentClient(input: $input) {
      errors
      metabaseJwtToken
      user {
        ...navbarUserFragment
        currentClient {
          id
          name
          metabaseDefaultDashboardId
          status
        }
      }
    }
  }
`
type Clients = {
  id: string
  cash: number
  hasIssues: boolean
  name: string
  revenue: number
  logo: string | null
  closeMonth: Date | null
}

type Props = {
  clients: clientsTableFragment$key
}

export const ClientsTable = ({ clients }: Props) => {
  const { currentClient } = useUserStore()
  const navigate = useNavigate()
  const {
    getClientSummaries: { edges },
  } = useFragment(ClientsTableFragment, clients)
  const [changeCurrentClient, changeCurrentClientLoading] =
    useMutation<ClientsTableChangeCurrentClientMutationType>(
      ClientsTableChangeCurrentClientMutation
    )

  const tableData: Clients[] = edges.map((client) => ({
    id: client.node.id,
    name: client.node.name,
    cash: client.node.cashBalance ?? 0,
    revenue: client.node.revenue ?? 0,
    hasIssues: client.node.issues ?? false,
    logo: client.node.logo,
    closeMonth: client.node.closeMonth ? new Date(client.node.closeMonth) : null,
  }))

  const handleClientClick = (clientId: string) => {
    if (currentClient.id === clientId) {
      navigate(pathConstants.HOME)
    } else {
      changeCurrentClient({
        variables: {
          input: {
            clientId,
          },
        },
        updater: (store, data) => {
          const newToken = data.changeCurrentClient?.metabaseJwtToken

          updateCurrentMetabaseTokenCache(store, data, newToken)
        },
        onCompleted: () => navigate(pathConstants.HOME),
      })
    }
  }

  const columns: ColumnDef<Clients>[] = [
    {
      header: "Client",
      accessorKey: "name",
      cell: (info) => {
        const clientName = info.getValue<string>()
        const clientLogo = info.row.original.logo

        return (
          <Group gap="0.5rem" style={{ cursor: "pointer" }}>
            <Avatar src={clientLogo} radius="sm" size="xs" color="blue">
              {clientName[0]}
            </Avatar>
            <Text size="sm" c="blue">
              {clientName}
            </Text>
          </Group>
        )
      },
    },
    {
      header: "Closed Month",
      accessorKey: "closeMonth",
      cell: (info) => {
        const date = info.getValue<Date>()
        return date ? (
          <Text size="sm">
            {date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long'
            })}
          </Text>
        ) : (
          <Text size="sm" c="dimmed">-</Text>
        )
      },
    },
    {
      header: "Cash Balance",
      accessorKey: "cash",
      cell: (info) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(info.getValue<number>()),
      meta: {
        align: "right",
      },
    },
    {
      header: "LM Revenue",
      accessorKey: "revenue",
      cell: (info) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(info.getValue<number>()),
      meta: {
        align: "right",
      },
    },
    {
      header: "",
      accessorKey: "hasIssues",
      cell: (info) => (
        <Group justify="flex-end">
          {info.getValue<boolean>() && (
            <HoverCard shadow="md" position="top-end">
              <HoverCard.Target>
                <Badge
                  variant="light"
                  color="red"
                  fw={400}
                  tt="capitalize"
                  fz={"xs"}
                >
                  <Group gap="0.5rem">
                    Connection Issues
                    <IconAlertTriangle size={12} />
                  </Group>
                </Badge>
              </HoverCard.Target>
              <HoverCard.Dropdown w={rem(186)}>
                <Text size="sm" c="gray.7" style={{ whiteSpace: "normal" }}>
                  {"There are connection issues that have to be resolved"}
                </Text>
                <Button
                  c="blue"
                  size="compact-sm"
                  variant="outline"
                  mt={rem(8)}
                  onClick={() => {
                    changeCurrentClient({
                      variables: {
                        input: {
                          clientId: info.row.original.id,
                        },
                      },
                      updater: (store, data) => {
                        const newToken =
                          data.changeCurrentClient?.metabaseJwtToken

                        updateCurrentMetabaseTokenCache(store, data, newToken)
                      },
                      onCompleted: () => navigate(pathConstants.CONNECTIONS),
                    })
                  }}
                >
                  Resolve Issues
                </Button>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
          <HoverMenu
            loading={changeCurrentClientLoading}
            className={styles.Menu}
            onClick={(to) =>
              to &&
              changeCurrentClient({
                variables: {
                  input: {
                    clientId: info.row.original.id,
                  },
                },
                updater: (store, data) => {
                  const newToken = data.changeCurrentClient?.metabaseJwtToken

                  updateCurrentMetabaseTokenCache(store, data, newToken)
                },
                onCompleted: () => navigate(to),
              })
            }
          />
        </Group>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
      meta: {
        disableRowClick: true,
        align: "right",
        className: styles.HoverCell,
      },
    },
  ]

  return (
    <Table
      data={tableData}
      columns={columns}
      onRowClick={(row) => handleClientClick(row.id)}
    />
  )
}
