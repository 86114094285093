/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ExportStatementsButton } from "../export-statements-button/export-statements-button"
import { SaveStatementsButton } from "../save-statements-button"
import { RepresentationEnum } from "../statements"
import { NumberFormatEnum, formatValue } from "../utils/format"
import {
  DisplayEnum,
  reportTableGetFinancialStatementQuery as ReportTableGetFinancialStatementQueryType,
  ReportTypeEnum,
} from "./__generated__/reportTableGetFinancialStatementQuery.graphql"
import styles from "./report-table.module.scss"
import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Group,
  Menu,
  Stack,
  Switch,
  Text,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { ErrorBadge } from "@shared/ui/error-badge"
import { ExpandableTable } from "@shared/ui/expandable-table"
import { IconCaretDownFilled, IconCaretRightFilled } from "@tabler/icons-react"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import { enUS } from "date-fns/locale/en-US"
import { pathConstants } from "frontend/routes/path-constants"
import React, { useMemo, useCallback, useState } from 'react'
import { PreloadedQuery, graphql, usePreloadedQuery } from "react-relay"
import { Link, createSearchParams, useLocation } from "react-router-dom"

export const ReportTableGetFinancialStatementQuery = graphql`
  query reportTableGetFinancialStatementQuery(
    $clientId: ID
    $startDate: UtcDate!
    $endDate: UtcDate!
    $systemConnectionIds: [ID!]!
    $display: DisplayEnum
    $reportType: ReportTypeEnum!
    $classIds: [ID!]
    $accountMapId: ID
  ) {
    getFinancialStatement(
      startDate: $startDate
      endDate: $endDate
      systemConnectionIds: $systemConnectionIds
      display: $display
      clientId: $clientId
      reportType: $reportType
      classIds: $classIds
      accountMapId: $accountMapId
    ) {
      data
      success
      reportName
    }
  }
`

const isCategory = (item: any): boolean => {
  // Top level items (like "ASSETS") are categories
  return !item.account_id && item.name && item.accounts;
};

const isGroup = (item: any): boolean => {
  // Items with account_type and sub-accounts but no account_id are groups
  return !item.account_id && item.account_type && item.accounts?.length > 0;
};

const isAccount = (item: any): boolean => {
  return !!item.account_id;
};

const generateExpandedObject = (data: any[], reportType: ReportTypeEnum): Record<string, boolean> => {
  const result: Record<string, boolean> = {}

  const processItem = (item: any, index: string) => {
    // For balance sheets, expand everything
    if (reportType === "BALANCE") {
      result[index] = true

      // Process nested items and expand them too
      if (item.groups) {
        item.groups.forEach((group: any, subIndex: number) => {
          processItem(group, `${index}.${subIndex}`)
        })
      }

      if (item.accounts) {
        item.accounts.forEach((account: any, subIndex: number) => {
          processItem(account, `${index}.${subIndex}`)
        })
      }
      return
    }

    // For cash flow statements
    if (reportType === "CASH") {
      // Expand top-level categories
      if (index.indexOf('.') === -1) {  // No dots means it's a top-level item
        result[index] = true
      } else {
        // Collapse everything else
        result[index] = false
      }

      // Continue processing nested items
      if (item.accounts) {
        item.accounts.forEach((account: any, subIndex: number) => {
          processItem(account, `${index}.${subIndex}`)
        })
      }
      return
    }

    // For other reports:
    // Always expand categories
    if (isCategory(item)) {
      result[index] = true
      return
    }

    // Keep groups collapsed by default
    if (isGroup(item)) {
      result[index] = false
      return
    }

    // Keep everything else collapsed by default
    result[index] = false

    // Process nested items
    if (item.groups) {
      item.groups.forEach((group: any, subIndex: number) => {
        processItem(group, `${index}.${subIndex}`)
      })
    }

    if (item.accounts) {
      item.accounts.forEach((account: any, subIndex: number) => {
        processItem(account, `${index}.${subIndex}`)
      })
    }
  }

  data.forEach((item, index) => {
    processItem(item, index.toString())
  })

  return result
}

const rowStyle = (context: CellContext<any, unknown>) => {
  const rowType = context.row.original.type
  const isExpanded = context.row.getIsExpanded()

  if (rowType == "checklist") {
    return styles["ReportTable--Checklist"]
  }

  if ((rowType == "parent" || rowType == "category_parent") && !isExpanded) {
    return styles["ReportTable--Child"]
  } else if (
    (rowType == "parent" || rowType == "category_parent") &&
    isExpanded
  ) {
    return styles["ReportTable--Parent__expanded"]
  }

  if (rowType == "totals") {
    return styles["ReportTable--Parent"]
  }

  if (rowType == "account_totals") {
    return styles["ReportTable--AccountTotals"]
  }

  if (rowType == "category_child") {
    return styles["ReportTable--CategoryChild"]
  }

  return styles["ReportTable--Child"]
}

type Props = {
  id: string | undefined
  systemConnectionIds: string[]
  startDate: string
  reportTypeLabel?: string
  endDate: string
  clientId: string | null
  clientName: string | null
  display: DisplayEnum
  reportType: ReportTypeEnum
  queryRef: PreloadedQuery<ReportTableGetFinancialStatementQueryType>
  formatFilters: string[]
  representationBy: RepresentationEnum
  zeroBalance: boolean
  setFormValueZeroBalance?: (value: boolean) => void
  classIds?: string[]
}

const isSubtotalCategory = (category: any): boolean => {
  // Subtotal categories typically:
  // 1. Don't have their own accounts
  // 2. Don't have groups
  // 3. Have totals
  return (!category.accounts || category.accounts.length === 0) &&
    (!category.groups || category.groups.length === 0) &&
    category.totals;
};

export const ReportTable = React.memo(({
  id,
  queryRef,
  clientName,
  formatFilters,
  reportTypeLabel,
  representationBy,
  zeroBalance,
  setFormValueZeroBalance,
  classIds,
  ...rest
}: Props) => {
  const location = useLocation()
  const { setCashFlowPath, setTransactionPath } = useUserStore()

  const redirectTransactionPath = useCallback(() => {
    setTransactionPath(`${location.pathname}${location.search}`)
  }, [location, setTransactionPath])

  const {
    getFinancialStatement: { data: result, reportName },
  } = usePreloadedQuery<ReportTableGetFinancialStatementQueryType>(
    ReportTableGetFinancialStatementQuery,
    queryRef
  )

  const [showZeroBalance, setShowZeroBalance] = useState(zeroBalance)

  const representation = useMemo(() => (
    rest.reportType === "INCOME" ? representationBy : RepresentationEnum.AMOUNT
  ), [rest.reportType, representationBy])

  const isPercentage = representation === RepresentationEnum.PERCENTAGE

  const generateTransactionUrl = useCallback((cell: any, startDate: string, endDate: string) => {
    if (rest.reportType === "CASH") return null

    const { display } = rest
    const connectionIds = cell.ledger_connection_id
      ? [cell.ledger_connection_id]
      : cell.ledger_connection_ids
    const accountIds = cell.ledger_account_id
      ? [cell.ledger_account_id]
      : cell.ledger_account_ids

    if (!accountIds || !connectionIds) {
      return null
    }

    const params = {
      accountIds,
      connectionIds,
      startDate: display == "ENTITY" ? rest.startDate : startDate,
      endDate: display == "ENTITY" ? rest.endDate : endDate,
    }

    return `${pathConstants.TRANSACTIONS}?${createSearchParams(
      params
    ).toString()}`
  }, [rest.reportType, rest.startDate, rest.endDate, rest.display])

  const rowValue = useCallback(({ row, column }: CellContext<any, unknown>) => {
    const rowType: string = row.original.type
    const isExpanded = row.getIsExpanded()
    const dateHeaders = result.date_headers || {}

    let redirectUrl = generateTransactionUrl(
      row.original,
      dateHeaders[column.id]?.start_date,
      dateHeaders[column.id]?.end_date
    )

    // For subtotal categories (like Gross Profit), use the totals directly
    if (isSubtotalCategory(row.original)) {
      const finalValue: number = column.id === 'total'
        ? row.original.total[representation]
        : row.original.totals[column.id]?.[representation] ?? 0
      return formatValue(
        "",
        () => { return },
        finalValue,
        formatFilters,
        !isPercentage,
        isPercentage
      )
    }

    // Hide values for expanded parent rows
    if ((rowType === "parent" || rowType === "category_parent") && isExpanded) {
      return ""
    }

    if (rowType == "checklist") {
      const finalValue: number = row.original[column.id]?.[representation] ?? 0

      return finalValue === 0 ? (
        formatValue(
          redirectUrl ?? "",
          () => redirectUrl && redirectTransactionPath(),
          finalValue,
          [],
          true
        )
      ) : (
        <Menu trigger="hover" position="top">
          <Menu.Target>
            <Group justify="flex-end" gap={8} w={"max-content"}>
              <ErrorBadge label="" />
              {formatValue(
                redirectUrl ?? "",
                () => redirectUrl && redirectTransactionPath(),
                finalValue,
                formatFilters,
                true,
                false,
                true
              )}
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Stack px={16} py={12} gap={8}>
              <Text size="sm">
                An error occurred. Review
                <br />
                the account configuration.
              </Text>
              <Button
                variant="outline"
                size="xs"
                w={64}
                miw={0}
                p={0}
                component={Link}
                to={pathConstants.CASH_FLOW_CONFIGURATION}
                onClick={() => {
                  setCashFlowPath(`${location.pathname}${location.search}`)
                }}
              >
                Review
              </Button>
            </Stack>
          </Menu.Dropdown>
        </Menu>
      )
    }

    if (rowType == "category_parent" && isExpanded) {
      return ""
    }

    if (column.id == "total") {
      const finalValue: number = isExpanded
        ? row.original[column.id]?.[representation] === 0
          ? 0
          : row.original[column.id]?.[representation]
        : rowType === "account_totals" || rowType === "child"
          ? row.original.total?.[representation]
          : row.original.accounts_total?.[representation]

      const shouldRedirect =
        ((rowType == "parent" || rowType == "category_parent") && isExpanded) ||
        rowType == "child"

      redirectUrl = shouldRedirect
        ? generateTransactionUrl(row.original, rest.startDate, rest.endDate)
        : null

      return formatValue(
        redirectUrl ?? "",
        () => redirectUrl && redirectTransactionPath(),
        finalValue,
        formatFilters,
        !isPercentage,
        isPercentage
      )
    }

    if ((rowType == "parent" || rowType == "category_parent") && !isExpanded) {
      const finalValue: number =
        row.original.accounts_totals[column.id]?.[representation] ?? 0

      return formatValue(
        "",
        () => {
          return
        },
        finalValue,
        formatFilters,
        !isPercentage,
        isPercentage
      )
    }

    if (!row.original[column.id]) {
      return ""
    }

    const currentAmount: number = row.original[column.id]?.[representation] ?? 0
    return formatValue(
      redirectUrl ?? "",
      () => redirectUrl && redirectTransactionPath(),
      currentAmount,
      formatFilters,
      ["account_totals", "category_child"].includes(rowType) && !isPercentage,
      !["parent", "child"].includes(rowType) && isPercentage
    )
  }, [formatFilters, isPercentage, representation, redirectTransactionPath])

  const columns = useMemo(() => {
    return result.headers
      ? result.headers.map((header: any, index: number) => {
        if (index === 0) {
          const col: ColumnDef<unknown> = {
            header: reportName ?? "",
            accessorKey: header,
            enableSorting: false,
            meta: {
              getCellContext: rowStyle,
            },
            cell: ({ row, getValue }) => (
              <Group pl={rem(row.depth * 24)} gap={rem(4)} miw={rem(340)}>
                <>
                  {row.getCanExpand() && (
                    <ActionIcon
                      variant="transparent"
                      c="gray"
                      size="sm"
                      onClick={() => row.toggleExpanded()}
                    >
                      {row.getIsExpanded() ? (
                        <IconCaretDownFilled />
                      ) : (
                        <IconCaretRightFilled />
                      )}
                    </ActionIcon>
                  )}
                  {getValue()}
                </>
              </Group>
            ),
          }

          return col
        }

        return {
          header: header,
          accessorKey: header,
          meta: {
            getCellContext: rowStyle,
            align: "right",
            className: header == "total" && styles["ReportTable--Total"],
          },
          cell: rowValue,
          enableSorting: false,
        }
      })
      : []
  }, [result.headers, reportName, rowValue])

  const getAccounts = (account: any) => {
    const isParent = account.accounts?.length > 0 || account.groups?.length > 0
    const hasAccountsTotals = account.accounts_totals

    const isAllZero = account.amounts
      ? Object.values(account.amounts)
        ?.map((item: any) => item[representation])
        .every((item: any) => item == 0)
      : true

    if (!isParent && isAllZero && !showZeroBalance) {
      return undefined
    }

    // Process nested groups recursively
    const processGroups = (groups: any[]) => {
      return groups.map(group => ({
        ...group,
        ...group.totals,
        name: group.name,
        type: 'parent',
        accounts_totals: group.totals,
        total: group.total,
        accounts_total: group.total,
        accounts: [
          // Process nested groups first (recursive call)
          ...(group.groups ? processGroups(group.groups) : []),
          // Then process accounts
          ...group.accounts
            .map(acc => getAccounts(acc))
            .filter(Boolean),
          // Add group total
          {
            ...group.totals,
            total: group.total,
            accounts_totals: group.totals,
            name: `Total ${group.name}`,
            type: "account_totals",
          }
        ]
      })).filter(Boolean)
    }

    const nestedGroups = account.groups ? processGroups(account.groups) : []

    const accountResult = {
      ...account,
      ...account.amounts,
      name: [account.account_number, account.account_name].join(" "),
      type: isParent ? "parent" : "child",
      accounts: isParent && hasAccountsTotals
        ? [
          ...nestedGroups,
          ...account.accounts
            .filter((acc: any) => acc !== undefined)
            .map((acc: any) => getAccounts(acc))
            .filter(Boolean),
          {
            ...account.accounts_totals,
            total: account.accounts_total,
            accounts_totals: account.accounts_totals,
            name: `Total ${[account.account_number, account.account_name].join(" ")}`,
            type: "account_totals",
          },
        ]
        : account.accounts
          ?.filter((acc: any) => acc !== undefined)
          .map((acc: any) => getAccounts(acc))
          .filter(Boolean),
    }

    if (Array.isArray(accountResult.accounts)) {
      accountResult.accounts = accountResult.accounts.filter(
        (acc: any) => acc !== undefined
      )
    }

    return accountResult
  }

  const data = useMemo(() => {
    return result.data
      ? result.data.categories
        .map((category: any) => {
          // Check if this is a subtotal category
          if (isSubtotalCategory(category)) {
            return {
              ...category,
              ...category.totals,
              name: category.name,
              type: "category_parent",
              accounts_totals: category.totals,
              total: category.total,
              accounts_total: category.total
            };
          }

          const isParent = category.accounts?.length > 0 || category.groups?.length > 0;

          // Process groups at the category level
          const processedGroups = category.groups?.map(group => ({
            ...group,
            ...group.totals,
            name: group.name,
            type: 'parent',
            accounts_totals: group.totals,
            total: group.total,
            accounts_total: group.total,
            accounts: [
              // Process any nested groups
              ...(group.groups?.map(subGroup => ({
                ...subGroup,
                ...subGroup.totals,
                name: subGroup.name,
                type: 'parent',
                accounts_totals: subGroup.totals,
                total: subGroup.total,
                accounts_total: subGroup.total,
                accounts: [
                  ...subGroup.accounts
                    .map(acc => getAccounts(acc))
                    .filter(Boolean),
                  {
                    ...subGroup.totals,
                    total: subGroup.total,
                    accounts_totals: subGroup.totals,
                    name: `Total ${subGroup.name}`,
                    type: "account_totals",
                  }
                ]
              })) || []),
              // Process direct accounts of the group
              ...group.accounts
                .map(acc => getAccounts(acc))
                .filter(Boolean),
              // Add group total
              {
                ...group.totals,
                total: group.total,
                accounts_totals: group.totals,
                name: `Total ${group.name}`,
                type: "account_totals",
              }
            ]
          })) || []

          return {
            ...category,
            name: category.key == "check" ? "Balance Check" : category.name,
            type: category.key == "check" ? "checklist" : "category_parent",
            accounts_totals: category.totals,
            total: category.total,
            accounts_total: category.total,
            accounts: [
              ...processedGroups,
              ...(category.accounts?.map(acc => getAccounts(acc)).filter(Boolean) || []),
              {
                ...category.totals,
                total: category.total,
                accounts_totals: category.totals,
                name: `Total ${category.name}`,
                type: "account_totals",
              }
            ]
          }
        })
        .filter(
          (category: any) =>
            category.key == "check" ||
            category.key?.includes('subtotal') ||
            !Object.values(category.totals)
              ?.map((item: any) => item[representation])
              .every((item: any) => item == 0 && !showZeroBalance)
        )
      : []
  }, [result.data, representation, showZeroBalance])

  return result.data ? (
    <Stack gap={"2rem"}>
      <Flex justify={"space-between"} align={"center"}>
        <Group gap={rem(12)}>
          <Text fw="bold" c="gray.7">
            {"Details"}
          </Text>
          <Divider
            orientation="vertical"
            style={{ borderColor: themeVars.colors.gray[3] }}
          />
          <Switch
            label={
              <Text size="sm" fw={500} c="dark.3" component="span">
                Include zero balance accounts
              </Text>
            }
            checked={showZeroBalance}
            onChange={(event) => {
              setShowZeroBalance(event.currentTarget.checked)
              if (setFormValueZeroBalance) {
                setFormValueZeroBalance(event.currentTarget.checked)
              }
            }}
            labelPosition="left"
          />
        </Group>
        <Group gap={rem(8)}>
          <ExportStatementsButton
            columns={columns}
            negativeInRed={formatFilters.includes(NumberFormatEnum.SHOW_RED)}
            exceptZeroAmmount={formatFilters.includes(
              NumberFormatEnum.EXCEPT_ZERO
            )}
            showDecimals={
              !formatFilters.includes(NumberFormatEnum.WITHOUT_CENTS)
            }
            divideOneThousand={formatFilters.includes(
              NumberFormatEnum.DIVIDE_1000
            )}
            data={data}
            title={`${clientName ?? ""} - ${reportTypeLabel ?? ""}`}
            filename={`${clientName ?? ""}-${reportTypeLabel ?? ""}`}
            footer={`Report generated on ${format(
              new Date(),
              "MMMM d, yyyy 'at' hh:mm aa OOO",
              {
                locale: enUS,
              }
            )}`}
            numberFormat={formatFilters}
            showZeroBalance={showZeroBalance}
            representation={representation}
            {...rest}
          />
          {!id && (
            <SaveStatementsButton
              {...rest}
              statementName={`${clientName ?? ""} - ${reportTypeLabel ?? ""}`}
              numberFormat={formatFilters}
              showZeroBalance={showZeroBalance}
              representation={representation}
            />
          )}
        </Group>
      </Flex>
      <ExpandableTable
        data={data}
        columns={columns}
        miw={120}
        expandedIds={generateExpandedObject(data, rest.reportType)}
      />
    </Stack>
  ) : (
    <></>
  )
})