/**
 * @generated SignedSource<<0e8dc55bc72de597173a7963c2bec595>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MetabaseQuestionStatusTypeEnum = "BROKEN" | "ONLINE";
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
export type WorkbookSpreadsheetStatusTypeEnum = "BROKEN" | "GOOGLE_ERROR" | "ONLINE" | "SPREADSHEETABLE_ERROR" | "SYNCING";
export type WorkbookSyncPeriodTypeEnum = "DAILY" | "MANUALLY";
import { FragmentRefs } from "relay-runtime";
export type spreadsheetsTableFragment$data = {
  readonly getUserWorkbooks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly bookId: string | null;
        readonly createdAt: string;
        readonly externalLink: string | null;
        readonly id: string;
        readonly lastSync: string | null;
        readonly name: string | null;
        readonly syncPeriod: WorkbookSyncPeriodTypeEnum | null;
        readonly updatedAt: string;
        readonly userConnection: {
          readonly id: string;
          readonly name: string;
          readonly status: StatusIntegrationTypeEnum;
          readonly user: {
            readonly avatar: string | null;
            readonly fullName: string | null;
          };
        };
        readonly workbookSpreadsheets: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly createdAt: string;
              readonly id: string;
              readonly lastSync: string | null;
              readonly name: string | null;
              readonly sheetId: string | null;
              readonly spreadsheetable: {
                readonly id?: string;
                readonly metabaseQuestionId?: number | null;
                readonly name?: string | null;
                readonly source?: string | null;
                readonly status?: MetabaseQuestionStatusTypeEnum | null;
                readonly updatedAt?: string;
              } | null;
              readonly status: WorkbookSpreadsheetStatusTypeEnum;
              readonly syncPeriod: number | null;
            };
          }>;
        };
      };
    }>;
  };
  readonly " $fragmentType": "spreadsheetsTableFragment";
};
export type spreadsheetsTableFragment$key = {
  readonly " $data"?: spreadsheetsTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"spreadsheetsTableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncPeriod",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSync",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v4/*: any*/),
  (v7/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "refetchQuestions"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "workflow"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "getUserWorkbooks"
        ]
      }
    ]
  },
  "name": "spreadsheetsTableFragment",
  "selections": [
    {
      "alias": "getUserWorkbooks",
      "args": [
        {
          "kind": "Variable",
          "name": "refetchQuestions",
          "variableName": "refetchQuestions"
        },
        {
          "kind": "Variable",
          "name": "workflow",
          "variableName": "workflow"
        }
      ],
      "concreteType": "WorkbookConnection",
      "kind": "LinkedField",
      "name": "__SpreadsheetsTableFragment_getUserWorkbooks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkbookEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Workbook",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookId",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalLink",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "direction",
                      "value": "WRITE"
                    }
                  ],
                  "concreteType": "WorkbookSpreadsheetConnection",
                  "kind": "LinkedField",
                  "name": "workbookSpreadsheets",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WorkbookSpreadsheetEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WorkbookSpreadsheet",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "sheetId",
                              "storageKey": null
                            },
                            (v5/*: any*/),
                            (v2/*: any*/),
                            (v6/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "spreadsheetable",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "metabaseQuestionId",
                                      "storageKey": null
                                    },
                                    (v2/*: any*/),
                                    (v6/*: any*/),
                                    (v7/*: any*/)
                                  ],
                                  "type": "MetabaseQuestion",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": (v8/*: any*/),
                                  "type": "SavedStatement",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": (v8/*: any*/),
                                  "type": "SavedTransaction",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "workbookSpreadsheets(direction:\"WRITE\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserIntegration",
                  "kind": "LinkedField",
                  "name": "userConnection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fullName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "avatar",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    (v2/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "bb03aabf751ac570d258cbaaeb447113";

export default node;
