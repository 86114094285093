/**
 * @generated SignedSource<<76c87f84f717c53c18ec5773fb226be6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type accountMapMapGroupsQuery$variables = {
  input: string;
};
export type accountMapMapGroupsQuery$data = {
  readonly getMapGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountType: string | null;
        readonly accounts: ReadonlyArray<{
          readonly accountType: string | null;
          readonly id: string;
          readonly integration: {
            readonly id: string;
            readonly name: string;
          };
          readonly name: string | null;
          readonly number: string | null;
          readonly systemAccountId: string | null;
        }>;
        readonly id: string;
        readonly mapDepartment: {
          readonly departmentName: string;
          readonly id: string;
        } | null;
        readonly name: string | null;
        readonly orderIndex: number;
        readonly unmapped: boolean | null;
      };
    }>;
  };
};
export type accountMapMapGroupsQuery = {
  response: accountMapMapGroupsQuery$data;
  variables: accountMapMapGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mapId",
        "variableName": "input"
      }
    ],
    "concreteType": "MapGroupConnection",
    "kind": "LinkedField",
    "name": "getMapGroups",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MapGroupEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MapGroup",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderIndex",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unmapped",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "systemAccountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SystemIntegration",
                    "kind": "LinkedField",
                    "name": "integration",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MapDepartment",
                "kind": "LinkedField",
                "name": "mapDepartment",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "departmentName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountMapMapGroupsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountMapMapGroupsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "63a9653ffd954ad3448853dd97fd81a9",
    "id": null,
    "metadata": {},
    "name": "accountMapMapGroupsQuery",
    "operationKind": "query",
    "text": "query accountMapMapGroupsQuery(\n  $input: ID!\n) {\n  getMapGroups(mapId: $input) {\n    edges {\n      node {\n        id\n        name\n        orderIndex\n        accountType\n        unmapped\n        accounts {\n          id\n          systemAccountId\n          number\n          name\n          accountType\n          integration {\n            id\n            name\n          }\n        }\n        mapDepartment {\n          id\n          departmentName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2181d12ba7a307a5b91a8904fc5a427";

export default node;
