/**
 * @generated SignedSource<<44f44f3adfb195b440c23fe9af99df6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DisplayEnum = "CLASS" | "DEPARTMENT" | "ENTITY" | "MONTH" | "QUARTER" | "YEAR";
export type ReportTypeEnum = "BALANCE" | "CASH" | "INCOME";
export type reportTableGetFinancialStatementQuery$variables = {
  accountMapId?: string | null;
  classIds?: ReadonlyArray<string> | null;
  clientId?: string | null;
  display?: DisplayEnum | null;
  endDate: string;
  reportType: ReportTypeEnum;
  startDate: string;
  systemConnectionIds: ReadonlyArray<string>;
};
export type reportTableGetFinancialStatementQuery$data = {
  readonly getFinancialStatement: {
    readonly data: any | null;
    readonly reportName: string | null;
    readonly success: boolean;
  };
};
export type reportTableGetFinancialStatementQuery = {
  response: reportTableGetFinancialStatementQuery$data;
  variables: reportTableGetFinancialStatementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountMapId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "classIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "display"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportType"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "systemConnectionIds"
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountMapId",
        "variableName": "accountMapId"
      },
      {
        "kind": "Variable",
        "name": "classIds",
        "variableName": "classIds"
      },
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "kind": "Variable",
        "name": "display",
        "variableName": "display"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "reportType",
        "variableName": "reportType"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "systemConnectionIds",
        "variableName": "systemConnectionIds"
      }
    ],
    "concreteType": "FinancialStatementsResults",
    "kind": "LinkedField",
    "name": "getFinancialStatement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reportName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "reportTableGetFinancialStatementQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "reportTableGetFinancialStatementQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "ba96211e32baa4a22ddd91d3c411e820",
    "id": null,
    "metadata": {},
    "name": "reportTableGetFinancialStatementQuery",
    "operationKind": "query",
    "text": "query reportTableGetFinancialStatementQuery(\n  $clientId: ID\n  $startDate: UtcDate!\n  $endDate: UtcDate!\n  $systemConnectionIds: [ID!]!\n  $display: DisplayEnum\n  $reportType: ReportTypeEnum!\n  $classIds: [ID!]\n  $accountMapId: ID\n) {\n  getFinancialStatement(startDate: $startDate, endDate: $endDate, systemConnectionIds: $systemConnectionIds, display: $display, clientId: $clientId, reportType: $reportType, classIds: $classIds, accountMapId: $accountMapId) {\n    data\n    success\n    reportName\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b93d9dae6fab0dfdea9f7c154bb9b4e";

export default node;
