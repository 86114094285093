import {
  WorkbookSyncPeriodTypeEnum,
  spreadsheetsGetSpreadsheetSettingsQuery,
} from "../__generated__/spreadsheetsGetSpreadsheetSettingsQuery.graphql"
import { SpreadsheetPlaceholder } from "../spreadsheet-placeholder/spreadsheet-placeholder"
import { GetSpreadsheetSettings } from "../spreadsheets"
import { spreadsheetsDrawerListSpreadsheetsQuery } from "../spreadsheets-drawer/__generated__/spreadsheetsDrawerListSpreadsheetsQuery.graphql"
import { ListSpreadsheetsFromWorkbook } from "../spreadsheets-drawer/spreadsheets-drawer"
import { SpreadsheetsItemsFields } from "../spreadsheets-drawer/spreadsheets-items-fields"
import { spreadsheetsItemsFieldsFragment$key } from "../spreadsheets-drawer/spreadsheets-items-fields/__generated__/spreadsheetsItemsFieldsFragment.graphql"
import { WorkbookSyncButton } from "../spreadsheets-table/workbook-sync-button"
import { connectSpreadsheetsDrawerDeleteWorkbookMutation } from "./__generated__/connectSpreadsheetsDrawerDeleteWorkbookMutation.graphql"
import {
  SpreadsheetUpdateSettingsInput,
  SpreadsheetableEnum,
  connectSpreadsheetsDrawerUpdateSpreadsheetSettingsMutation,
} from "./__generated__/connectSpreadsheetsDrawerUpdateSpreadsheetSettingsMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  Button,
  Divider,
  Flex,
  Notification,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import authEnvironment from "@shared/relay/environment/auth-environment"
import { AppDrawer } from "@shared/ui/app-drawer"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { Select } from "@shared/ui/select"
import { getDaysAgo } from "@shared/utils/helpers"
import { Suspense, useEffect, useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form"
import {
  ConnectionHandler,
  PreloadedQuery,
  commitLocalUpdate,
  graphql,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay"
import { z } from "zod"

const SELECT_ERROR_MESSAGE = "Please select an option."
const TEXT_ERROR_MESSAGE = "Please fill out this required field."
const TEXT_DUPLICATE_ERROR_MESSAGE = "This Sheet Name already exists."

const DeleteWorkbook = graphql`
  mutation connectSpreadsheetsDrawerDeleteWorkbookMutation(
    $workbookId: ID!
    $connections: [ID!]!
  ) {
    deleteWorkbook(input: { workbookId: $workbookId }) {
      workbook {
        id @deleteEdge(connections: $connections)
      }
    }
  }
`
const UpdateWorkbookSettings = graphql`
  mutation connectSpreadsheetsDrawerUpdateSpreadsheetSettingsMutation(
    $input: UpdateSpreadsheetSettingsInput!
  ) {
    updateSpreadsheetSettings(input: $input) {
      clientMutationId
      errors
      workbook {
        bookId
        createdAt
        id
        name
        syncPeriod
        updatedAt
        userConnection {
          user {
            fullName
          }
          id
        }
        workbookSpreadsheets(direction: WRITE) {
          edges {
            node {
              createdAt
              id
              lastSync
              name
              status
              syncPeriod
              spreadsheetable {
                ... on MetabaseQuestion {
                  id
                  metabaseQuestionId
                  name
                  status
                }
                ... on SavedStatement {
                  id
                  name
                  updatedAt
                }
                ... on SavedTransaction {
                  id
                  name
                  updatedAt
                }
              }
            }
          }
        }
      }
    }
  }
`

const ConnectSpreadsheetsSchema = z
  .object({
    syncPeriod: z.custom<WorkbookSyncPeriodTypeEnum>(),
    spreadsheets: z
      .array(
        z.object({
          spreadsheetable: z
            .string({ invalid_type_error: SELECT_ERROR_MESSAGE })
            .min(1, { message: SELECT_ERROR_MESSAGE }),
          sheet: z
            .string({ invalid_type_error: SELECT_ERROR_MESSAGE })
            .min(1, { message: SELECT_ERROR_MESSAGE }),
          name: z.string().min(1),
          newName: z.string().optional(),
          spreadsheetableType: z.custom<SpreadsheetableEnum>(),
          id: z.string().min(1),
          nodeId: z.string().optional(),
          erroneousSheet: z.string().optional(),
        })
      )
      .nonempty(),
    deletedSpreadsheets: z.array(z.string()),
  })
  .superRefine(({ spreadsheets }, context) => {
    spreadsheets.forEach((spreadsheetItem, index) => {
      const spreadsheetsNames = new Set(
        spreadsheets
          .filter(
            (spreadsheet) =>
              spreadsheet.id != spreadsheetItem.id &&
              !!spreadsheet.newName?.trim()
          )
          .flatMap((spreadsheet) => spreadsheet.newName?.trim())
      )
      if (
        spreadsheetItem.sheet === "new_spreadsheet" &&
        !spreadsheetItem.newName?.trim()
      ) {
        context.addIssue({
          code: "custom",
          path: [`spreadsheets.${index}.newName`],
          message: TEXT_ERROR_MESSAGE,
        })
      }
      if (spreadsheetsNames.has(spreadsheetItem.newName?.trim())) {
        context.addIssue({
          code: "custom",
          path: [`spreadsheets.${index}.newName`],
          message: TEXT_DUPLICATE_ERROR_MESSAGE,
        })
      }
    })
  })

export type ConnectSpreadsheetsType = z.infer<typeof ConnectSpreadsheetsSchema>
export type SpreadsheetsType = Extract<ConnectSpreadsheetsType, "spreadsheets">

type Props = {
  opened: boolean
  onClose: () => void
  connectedWorkbookRef: PreloadedQuery<spreadsheetsGetSpreadsheetSettingsQuery>
  reportsData: spreadsheetsItemsFieldsFragment$key
}

export const ConnectSpreadsheetsDrawer = ({
  onClose,
  opened,
  connectedWorkbookRef,
  reportsData,
}: Props) => {
  const { getSpreadsheetSettings } =
    usePreloadedQuery<spreadsheetsGetSpreadsheetSettingsQuery>(
      GetSpreadsheetSettings,
      connectedWorkbookRef
    )
  const [updateWorkbookSettings, updateLoading] =
    useMutation<connectSpreadsheetsDrawerUpdateSpreadsheetSettingsMutation>(
      UpdateWorkbookSettings
    )
  const [deleteWorkbook, deleteLoading] =
    useMutation<connectSpreadsheetsDrawerDeleteWorkbookMutation>(DeleteWorkbook)

  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false)

  const [queryError, setQueryError] = useState<null | boolean>(null)

  const readonly = getSpreadsheetSettings?.readonly ?? true

  const userConnectionIssues = ["SYNC_FAILED", "BROKEN"].includes(
    getSpreadsheetSettings?.userConnection.status ?? ""
  )
  const workbookSyncing =
    getSpreadsheetSettings?.userConnection.status === "SYNCING" ? true : false

  const spreadsheetsIssues =
    getSpreadsheetSettings?.workbookSpreadsheets.edges.some(({ node }) =>
      ["BROKEN", "GOOGLE_ERROR", "SPREADSHEETABLE_ERROR"].includes(node.status)
    )
  const spreadsheetsSyncing =
    getSpreadsheetSettings?.workbookSpreadsheets.edges.some(
      ({ node }) => node.status === "SYNCING"
    )
  const spreadsheetablesIssues =
    getSpreadsheetSettings?.workbookSpreadsheets.edges.some(
      ({ node }) => node.spreadsheetable.status === "BROKEN"
    )
  const [
    spreadsheetsIntegrationsQueryRef,
    listSpreadsheetsIntegrations,
    disposeSpreadsheetsIntegrations,
  ] = useQueryLoader<spreadsheetsDrawerListSpreadsheetsQuery>(
    ListSpreadsheetsFromWorkbook
  )
  const form = useForm<ConnectSpreadsheetsType>({
    defaultValues: {
      spreadsheets: [
        {
          id: crypto.randomUUID(),
          spreadsheetable: "",
          spreadsheetableType: "MetabaseQuestion",
          sheet: "",
        },
      ],
      deletedSpreadsheets: [],
    },
    mode: "all",
    resolver: zodResolver(ConnectSpreadsheetsSchema),
    disabled: readonly,
  })

  const { append, remove } = useFieldArray({
    control: form.control,
    name: "spreadsheets",
  })

  const onAppendSpreadsheet = () => {
    append({
      id: crypto.randomUUID(),
      spreadsheetable: "",
      sheet: "",
      name: "",
      spreadsheetableType: "MetabaseQuestion",
    })
    form.getValues("spreadsheets").length === 1 &&
      form.clearErrors(`spreadsheets`)
  }
  const onRemoveSpreadsheet = (index: number) => {
    const deletedSpreadsheetsValue = form.getValues("deletedSpreadsheets")
    const spreadsheetItem = form.getValues("spreadsheets")[index]
    remove(index)
    if (!spreadsheetItem.nodeId) {
      return
    }
    form.setValue("deletedSpreadsheets", [
      ...deletedSpreadsheetsValue,
      spreadsheetItem.nodeId,
    ])
    form.trigger("deletedSpreadsheets")
  }
  const onDeleteConnection = () => {
    const userWorkbooksId = ConnectionHandler.getConnectionID(
      "root",
      "SpreadsheetsTableFragment_getUserWorkbooks",
      {
        refetchQuestions: true,
      }
    )
    getSpreadsheetSettings?.id &&
      deleteWorkbook({
        variables: {
          workbookId: getSpreadsheetSettings?.id,
          connections: [userWorkbooksId],
        },
        onCompleted: () => {
          notifications.show({
            title: "Workbook Successfully unlinked",
            message:
              "Your workbook connection and it's spreadsheets were successfully unlinked",
            variant: "success",
          })
          closeDeleteModal()
          onClose()
        },
        onError: (e) => {
          notifications.show({
            title: "An error ocurred",
            message: e.message,
            variant: "error",
          })
          closeDeleteModal()
          onClose()
        },
      })
  }

  const onSubmit: SubmitHandler<ConnectSpreadsheetsType> = (values) => {
    if (!getSpreadsheetSettings?.id) return
    const spreadsheetsValues: Array<SpreadsheetUpdateSettingsInput> =
      values.spreadsheets.map((spreadsheet) => ({
        id: spreadsheet.nodeId ?? null,
        spreadsheetableId: spreadsheet.spreadsheetable,
        spreadsheetableType: spreadsheet.spreadsheetableType,
        spreadsheetId: spreadsheet.sheet,
        spreadsheetName: spreadsheet.name,
        // eslint-disable-next-line unicorn/no-negated-condition, no-extra-boolean-cast
        newSpreadsheetName: !!spreadsheet.newName?.trim()
          ? spreadsheet.newName?.trim()
          : null,
      }))
    updateWorkbookSettings({
      variables: {
        input: {
          deletedSpreadsheetIds: values.deletedSpreadsheets,
          spreadsheets: spreadsheetsValues,
          syncPeriod: values.syncPeriod,
          workbookId: getSpreadsheetSettings?.id,
        },
      },
      onCompleted: () => {
        notifications.show({
          title: "Updates Saved Successfully",
          message: "It will take a while to synchronize all the data.",
          variant: "success",
        })
        form.reset()
        onClose()
      },
      onError: (err) => {
        notifications.show({
          title: "Error",
          message:
            err.message ?? "An error occurred updating connected spreadsheets",
          variant: "success",
        })
        form.reset()
        onClose()
      },
    })
    //TODO: add mutation that sends spreadsheet data
  }

  useEffect(() => {
    const userSpreadsheets =
      getSpreadsheetSettings?.workbookSpreadsheets.edges.map(({ node }) => ({
        id: node.id,
        nodeId: node.id,
        spreadsheetable: node.spreadsheetable.id,
        spreadsheetableType: node.spreadsheetableType,
        sheet: node.sheetId ?? "",
        name: node.name ?? "",
        erroneousSheet: ["GOOGLE_ERROR", "BROKEN"].includes(node.status)
          ? node.name
          : undefined,
      }))
    if (userSpreadsheets && userSpreadsheets.length > 0) {
      form.setValue("spreadsheets", userSpreadsheets as SpreadsheetsType)
      form.trigger("spreadsheets")
    }
    if (getSpreadsheetSettings?.syncPeriod) {
      form.setValue("syncPeriod", getSpreadsheetSettings?.syncPeriod)
      form.trigger("syncPeriod")
    }
  }, [
    form,
    opened,
    getSpreadsheetSettings?.syncPeriod,
    getSpreadsheetSettings?.workbookSpreadsheets.edges,
  ])

  useEffect(() => {
    !readonly &&
      getSpreadsheetSettings?.userConnection.id &&
      getSpreadsheetSettings?.bookId &&
      !userConnectionIssues &&
      listSpreadsheetsIntegrations(
        {
          userConnectionId: getSpreadsheetSettings?.userConnection.id,
          workbookIntegrationId: getSpreadsheetSettings?.bookId,
        },
        {
          fetchPolicy: "network-only",
        }
      )
  }, [
    listSpreadsheetsIntegrations,
    getSpreadsheetSettings?.bookId,
    getSpreadsheetSettings?.userConnection.id,
    userConnectionIssues,
    readonly,
  ])

  const disabledSpreadsheets = useMemo(
    () => (
      <Stack gap={"1rem"}>
        <Text size="sm" fw={500}>
          Spreadsheets
        </Text>
        {getSpreadsheetSettings?.workbookSpreadsheets.edges.map(({ node }) => (
          <SpreadsheetPlaceholder
            key={node.id}
            spreadsheetableError={
              node.spreadsheetable.status == "BROKEN" ? "Review Source" : ""
            }
            spreadsheetableText={node.spreadsheetable.name ?? ""}
            spreadsheetText={node.name ?? ""}
            spreadsheetError={
              ["BROKEN", "GOOGLE_ERROR", "SPREADSHEETABLE_ERROR"].includes(
                node.status
              )
                ? "Review Spreadsheet File"
                : ""
            }
            defaultOpen={readonly}
            spreadsheetableType={node.spreadsheetableType ?? ""}
          />
        ))}
      </Stack>
    ),
    [getSpreadsheetSettings?.workbookSpreadsheets.edges, readonly]
  )

  useEffect(() => {
    if (getSpreadsheetSettings?.id && (queryError || userConnectionIssues)) {
      commitLocalUpdate(authEnvironment, (store) => {
        const spreadSheetsSettings = store.get(getSpreadsheetSettings?.id)

        if (queryError) {
          spreadSheetsSettings
            ?.getLinkedRecord("workbookSpreadsheets")
            ?.getLinkedRecords("edges")
            ?.map((item) =>
              item.getLinkedRecord("node")?.setValue("BROKEN", "status")
            )
        } else if (userConnectionIssues) {
          spreadSheetsSettings
            ?.getLinkedRecord("userConnection")
            ?.setValue("BROKEN", "status")
        }
      })
    }
  }, [queryError, userConnectionIssues, getSpreadsheetSettings?.id])

  return (
    <>
      <AppDrawer
        opened={opened}
        size={500}
        title={"Workbook Details"}
        onClose={() => {
          onClose()
          form.reset()
          disposeSpreadsheetsIntegrations()
        }}
        closeOnClickOutside={!form.formState.isDirty}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            style={{ height: "100%" }}
          >
            <ScrollArea
              h={readonly ? "100%" : "calc(100% - 3.5rem)"}
              scrollbarSize={0}
              styles={{
                viewport: {
                  padding: "1rem",
                  paddingTop: "0.5rem",
                },
                scrollbar: {
                  width: 0,
                },
              }}
            >
              <Stack gap={rem(16)}>
                <Notification
                  withCloseButton={false}
                  color="yellow"
                  withBorder
                  style={{ boxShadow: "var(--mantine-shadow-xs)" }}
                >
                  <Text c="gray" size="sm">
                    Establishing a connection with this spreadsheet will result
                    in the overwrite of existing data.
                  </Text>
                </Notification>
                <Select
                  label="Connection"
                  error={
                    userConnectionIssues &&
                    "This connection is broken, go to connections and reconnect."
                  }
                  disabled
                  value={getSpreadsheetSettings?.userConnection.name ?? ""}
                  data={[getSpreadsheetSettings?.userConnection.name ?? ""]}
                />
                <Select
                  label="Workbook"
                  disabled
                  error={queryError && "Check your connected workbook status."}
                  value={getSpreadsheetSettings?.name ?? ""}
                  data={[getSpreadsheetSettings?.name ?? ""]}
                />
                <Controller
                  control={form.control}
                  name="syncPeriod"
                  render={({
                    field: { onChange, onBlur, value, ref, disabled },
                  }) => (
                    <Select
                      ref={ref}
                      allowDeselect={false}
                      label="Sync Period"
                      error={form.formState.errors.syncPeriod?.message}
                      onChange={onChange}
                      value={value ?? ""}
                      disabled={
                        disabled || userConnectionIssues || !!queryError
                      }
                      onBlur={onBlur}
                      data={[
                        { label: "Manually", value: "MANUALLY" },
                        { label: "Daily", value: "DAILY" },
                      ]}
                    />
                  )}
                />
                <Stack py={rem(12)} px={rem(8)} gap={rem(12)}>
                  <Flex w="100%" align="start" justify="space-between">
                    <Text size="sm" fw={500}>
                      Last Sync
                    </Text>
                    {getSpreadsheetSettings?.id && (
                      <WorkbookSyncButton
                        workbookId={getSpreadsheetSettings?.id}
                        disabled={
                          userConnectionIssues ||
                          !!spreadsheetablesIssues ||
                          !!spreadsheetsIssues ||
                          !!queryError
                        }
                        loading={!!spreadsheetsSyncing}
                        onClick={() => {
                          form.reset()
                          onClose()
                        }}
                      />
                    )}
                  </Flex>
                  <Text size="sm" c="gray">
                    {typeof getSpreadsheetSettings?.lastSync === "string"
                      ? getDaysAgo(new Date(getSpreadsheetSettings?.lastSync))
                      : "--"}
                  </Text>
                </Stack>
                <Divider />
                <ErrorBoundary
                  onError={() => {
                    setQueryError(true)
                  }}
                  fallbackRender={() => disabledSpreadsheets}
                >
                  <Suspense
                    fallback={
                      <>
                        <Skeleton height={rem(16)} />
                        <Skeleton height={rem(154)} mt={rem(16)} />
                      </>
                    }
                  >
                    {readonly || userConnectionIssues
                      ? disabledSpreadsheets
                      : spreadsheetsIntegrationsQueryRef && (
                        <SpreadsheetsItemsFields
                          isNewWorkbook={false}
                          reportsData={reportsData}
                          itemsCollapsed={true}
                          disabledSpreadsheets={
                            readonly || userConnectionIssues
                          }
                          onAppendSpreadsheet={onAppendSpreadsheet}
                          onRemoveSpreadsheet={onRemoveSpreadsheet}
                          queryRef={spreadsheetsIntegrationsQueryRef}
                          disabled={readonly}
                        />
                      )}
                  </Suspense>
                </ErrorBoundary>
              </Stack>
            </ScrollArea>
            {!readonly && (
              <Box
                style={{
                  position: "absolute",
                  padding: "1rem",
                  paddingBottom: 0,
                  width: "100%",
                  bottom: "1rem",
                }}
                bg="white"
              >
                <Flex w="100%" gap={rem(8)}>
                  <Button
                    color="red"
                    variant="outline"
                    w="100%"
                    loading={deleteLoading}
                    onClick={openDeleteModal}
                    disabled={readonly}
                  >
                    Unlink
                  </Button>
                  <Button
                    w="100%"
                    type="submit"
                    disabled={
                      readonly ||
                      !form.formState.isValid ||
                      (form.formState.errors.spreadsheets?.length || 0) > 0 ||
                      Object.keys(form.formState.dirtyFields).length === 0 ||
                      userConnectionIssues ||
                      workbookSyncing ||
                      !!queryError
                    }
                    loading={updateLoading || workbookSyncing}
                  >
                    Save
                  </Button>
                </Flex>
              </Box>
            )}
          </form>
        </FormProvider>
      </AppDrawer>
      <ConfirmationModal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        confirmButtonProps={{
          children: "Unlink",
          color: "red",
          onClick: onDeleteConnection,
        }}
        cancelButtonProps={{
          onClick: closeDeleteModal,
        }}
        title={"Are your sure you want to unlink this workbook?"}
      >
        By proceeding with the process, you will unlink all the spreadsheets
        within this workbook.
      </ConfirmationModal>
    </>
  )
}
