import { portfolioGetClientSummariesQuery as PortfolioGetClientSummariesQueryType } from "./__generated__/portfolioGetClientSummariesQuery.graphql"
import { ClientsTable } from "./clients-table"
import styles from "./styles.module.scss"
import { Box, Button, Group, Space, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import EmptyPortfolio from "@shared/assets/empty-portfolio.svg"
import { useUserStore } from "@shared/store"
import { ClientDrawer } from "@shared/ui/client-drawer"
import { graphql, useLazyLoadQuery } from "react-relay"

const PortfolioGetClientSummariesQuery = graphql`
  query portfolioGetClientSummariesQuery {
    getClientSummaries {
      count
    }
    ...clientsTableFragment
  }
`

export const Portfolio = () => {
  const { isAdmin, currentClient } = useUserStore()

  const clientSummariesData = useLazyLoadQuery<PortfolioGetClientSummariesQueryType>(
    PortfolioGetClientSummariesQuery,
    {},
    { fetchKey: currentClient.id ?? "" }
  )
  const [opened, { open, close }] = useDisclosure(false)

  const hasClients = clientSummariesData.getClientSummaries.count > 0
  return (
    <Box className={styles.Portfolio}>
      <Group justify="space-between">
        <Text size="xxl" fw={700} c={"gray"} component="h1">
          Portfolio
        </Text>
        {isAdmin && hasClients && <Button onClick={open}>New Client</Button>}
      </Group>
      {hasClients ? (
        <>
          <Space h="3.5rem" />
          <ClientsTable clients={clientSummariesData} />
        </>
      ) : (
        <Box className={styles.Portfolio__Empty}>
          <Stack gap="2rem" align="center" maw="20.875rem">
            <img src={EmptyPortfolio} />
            {isAdmin ? (
              <>
                <Text ta="center" c="gray">
                  It looks like you haven&apos;t added any clients yet.
                  Let&apos;s get started, add your first client here.
                </Text>
                <Button onClick={open}>Add Client</Button>
              </>
            ) : (
              <Text ta="center" c="gray">
                It looks like you haven&apos;t been assigned to any clients yet.
              </Text>
            )}
          </Stack>
        </Box>
      )}
      <ClientDrawer opened={opened} onClose={close} />
    </Box>
  )
}
