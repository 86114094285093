/**
 * @generated SignedSource<<51958df314ffeadbab2b5bb2a9877a1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReorderMapGroupMutationInput = {
  clientMutationId?: string | null;
  mapGroupId: string;
  newOrderIndex: number;
};
export type accountMapReorderGroupMutation$variables = {
  input: ReorderMapGroupMutationInput;
};
export type accountMapReorderGroupMutation$data = {
  readonly reorderMapGroup: {
    readonly mapGroup: {
      readonly id: string;
      readonly orderIndex: number;
    } | null;
  } | null;
};
export type accountMapReorderGroupMutation = {
  response: accountMapReorderGroupMutation$data;
  variables: accountMapReorderGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReorderMapGroupMutationPayload",
    "kind": "LinkedField",
    "name": "reorderMapGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MapGroup",
        "kind": "LinkedField",
        "name": "mapGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orderIndex",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountMapReorderGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountMapReorderGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4baaba34a7287da65da839df4fd7510c",
    "id": null,
    "metadata": {},
    "name": "accountMapReorderGroupMutation",
    "operationKind": "mutation",
    "text": "mutation accountMapReorderGroupMutation(\n  $input: ReorderMapGroupMutationInput!\n) {\n  reorderMapGroup(input: $input) {\n    mapGroup {\n      id\n      orderIndex\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1953fbe6c8fbb2895e4fc2163a223a63";

export default node;
