/**
 * @generated SignedSource<<74a70365c5e4a84538fd8f158db84d4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type portfolioGetClientSummariesQuery$variables = {};
export type portfolioGetClientSummariesQuery$data = {
  readonly getClientSummaries: {
    readonly count: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"clientsTableFragment">;
};
export type portfolioGetClientSummariesQuery = {
  response: portfolioGetClientSummariesQuery$data;
  variables: portfolioGetClientSummariesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "portfolioGetClientSummariesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryConnection",
        "kind": "LinkedField",
        "name": "getClientSummaries",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "clientsTableFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "portfolioGetClientSummariesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSummaryConnection",
        "kind": "LinkedField",
        "name": "getClientSummaries",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientSummaryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientSummary",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issues",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cashBalance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "revenue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "closeMonth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "filters": null,
        "handle": "connection",
        "key": "ClientsTableFragment_getClientSummaries",
        "kind": "LinkedHandle",
        "name": "getClientSummaries"
      }
    ]
  },
  "params": {
    "cacheID": "0fb430107de1f9d48d65b605520e6d8a",
    "id": null,
    "metadata": {},
    "name": "portfolioGetClientSummariesQuery",
    "operationKind": "query",
    "text": "query portfolioGetClientSummariesQuery {\n  getClientSummaries {\n    count\n  }\n  ...clientsTableFragment\n}\n\nfragment clientsTableFragment on Query {\n  getClientSummaries {\n    edges {\n      node {\n        id\n        name\n        issues\n        status\n        logo\n        cashBalance\n        revenue\n        closeMonth\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5027aff200c024512e150486416cb00";

export default node;
